<template>
  <!-- 原单 -->
  <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
    <OrderInfoUI
      :order="order"
      :peoList="peoList"
      :confirmLoading="confirmLoading"
      :cancelLoading="cancelLoading"
      @refund="fetchData"
      @confirm="confirm"
      @cancel="cancel"
    />
  </van-pull-refresh>
</template>

<script>
import { mapGetters } from "vuex";
import OrderInfoUI from "./components/PaymentUI";
import {
  changeOrderStatusReload,
  confirmChangeOrder,
  cancelChangeTicket,
} from "@/api/train";
export default {
  computed: {
    ...mapGetters(["orderItemChange"]),
  },
  components: { OrderInfoUI },
  data() {
    return {
      order: {}, // 订单
      peoList: [], // 人员
      confirmLoading: false, // 按钮转圈圈
      cancelLoading: false,
      isLoading: false, // 刷新
      canRefresh: true, // 可否刷新
    };
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      setTimeout(() => {
        this.fetchData(false);
      }, 500);
    },
    // ajax 获取数据
    fetchData(f = true) {
      
      console.log(this.orderItemChange)
      if (f) this.canRefresh = true;
      if (this.canRefresh)
        changeOrderStatusReload({
          corp: this.orderItemChange.corp,
          orderNo: this.orderItemChange.orderNo, // 改签单订单号
          outOrderNo: this.orderItemChange.outOrderNo, // 改签单
        }).then((response) => {
          this.order = response;
          this.peoList = [response];
          this.isLoading = false;
          this.canRefresh = false;
          setTimeout(() => {
            this.canRefresh = true;
          }, 30 * 1000);
        });
      else {
        this.$notify({
          type: "warning",
          message: "请勿频繁刷新，请30秒后再次尝试。",
        });
        this.isLoading = false;
      }
    },
    // 出票
    confirm() {
      this.confirmLoading = true;
      confirmChangeOrder({
        orderNo: this.order.orderNo,
        outOrderNo: this.order.outOrderNo,
        corp: this.order.corp,
        originalOrderNo: this.order.bz3 || "",
        itemId: "",
      })
        .then((response) => {
          // 如果经费不足提示。
          if (!response.success) {
            this.confirmLoading = false;
            return this.$toast.fail(response.msg);
          }
          // setTimeout(() => {
          this.$toast.success("申请出票成功，请稍后。");
          this.fetchData();
          this.confirmLoading = false;
          // }, 30 * 1000);
        })
        .catch(() => {
          this.confirmLoading = false;
        });
    },
    // 取消出票
    cancel() {
      this.$dialog.confirm({
        title: '提示',
        message: '是否要取消订单？',
      })
        .then(() => {
          this.cancelLoading = true;
          cancelChangeTicket({
            orderNo: this.order.orderNo,
            outOrderNo: this.order.outOrderNo,
            corp: this.order.corp,
            originalOrderNo: this.order.bz3 || "",
            itemId: "",
          })
            .then((response) => {
              this.$toast.success("申请取消成功，请稍后。");
              setTimeout(() => {
                this.cancelLoading = false;
                this.$router.go(-1);
              }, 1000 * 15);
            })
            .catch(() => {
              this.cancelLoading = false;
            });
        })
    },
  },
  activated() {
    this.order = {};
    this.peoList = [];
    this.fetchData();
  },
};
</script>